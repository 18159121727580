export const httpLoan = () => 'contractloan';
export const httpLoanId = (id: number) => `contractloan/${id}`;
export const httpLoanPaymentPeriods = () => `contractloan/paymentperiods`;
export const httpLoanStyles = () => `contractloan/styles`;
export const httpLoanTransactions = (loanId: number) => `contractloan/schedule/${loanId}`;
export const httpLoanTransactionsGenerate = (loanId: number) => `contractloan/${loanId}/schedule/generate`;
export const httpLoanDocs = (loanId: number) => `contractloan/${loanId}/docs`;
export const httpLoanDocId = (loanId: number, docId: number) => `contractloan/${loanId}/docs/${docId}`;
export const httpLoanDocIdDownload = (loanId: number, docId: number) => `contractloan/${loanId}/docs/download/${docId}`;
export const httpLoanDocUpload = (loanId: number) => `contractloan/${loanId}/docs/upload`;
export const httpLoanWithdrawals = () => `contractloan/withdrawals/release`;
export const httpLoanSchedule = (loanId: number, type: string) => `contractloan/${loanId}/schedule/${type}`;
export const httpLoanScheduleGenerate = (loanId: number, type: string) => `contractloan/schedule/${loanId}/${type}`;
export const httpLoanActivate = (loanId: number, transId: number) => `contractloan/${loanId}/activate/${transId}`;
export const httpLoanLineOfCreditTransaction = (loanId: number) => `contractloan/${loanId}/payment`;
export const httpLoanLineOfCreditVenues = () => `contractloan/lineofcredits/venues`;
export const httpLoanIssuerPortfolio = () => `issuerview/basicportfolio`;
