import { Injectable, inject } from '@angular/core';
import { DBProperty } from '@mca/shared/domain';
import { ApiService, dateAsYYYYMMDD, removeObjectValues } from '@mca/shared/util';
import { TransRec } from '@mca/shared/domain';
import { parseISO } from 'date-fns';
import { map } from 'rxjs';
import { ApiLoan, Loan } from '../entitites/loan';
import { LoanDoc } from '../entitites/loan-doc';
import { ApiLoanTransaction, LoanScheduleType, LoanTransaction } from '../entitites/loan-transaction';
import {
  httpLoan,
  httpLoanDocId,
  httpLoanDocIdDownload,
  httpLoanDocs,
  httpLoanDocUpload,
  httpLoanId,
  httpLoanSchedule,
  httpLoanScheduleGenerate,
  httpLoanPaymentPeriods,
  httpLoanStyles,
  httpLoanTransactions,
  httpLoanTransactionsGenerate,
  httpLoanWithdrawals,
  httpLoanActivate,
  httpLoanLineOfCreditTransaction,
  httpLoanLineOfCreditVenues,
  httpLoanIssuerPortfolio,
} from '../infrastructure/loan-http-endpoints';
import { LoanWithdrawals } from '../entitites/loan-withdrawals';
import { IssuerPosition, IssuerSummary } from '../entitites/issuer-position';

@Injectable({
  providedIn: 'root',
})
export class LoanService {
  private apiService = inject(ApiService);

  getLoanList(params: { venue_id?: number; status?: number }) {
    return this.apiService.get<ApiLoan[]>(httpLoan(), { params }).pipe(map(loans => loans.map(this.apiLoanToLoan)));
  }

  getLoan(id: number) {
    return this.apiService.get<ApiLoan>(httpLoanId(id)).pipe(map(this.apiLoanToLoan));
  }

  createLoan(loan: Omit<Loan, 'id'>) {
    return this.apiService.post(httpLoan(), this.loanToApiLoan(loan as Loan));
  }

  updateLoan(id: number, loan: Omit<Loan, 'id'>) {
    return this.apiService.put(httpLoanId(id), this.loanToApiLoan(loan as Loan));
  }

  getLoanPaymentPeriods() {
    return this.apiService.get<DBProperty[]>(httpLoanPaymentPeriods());
  }

  getLoanStyles() {
    return this.apiService.get<DBProperty[]>(httpLoanStyles());
  }

  getLoanTransactions(loanId: number) {
    return this.apiService.get<TransRec[]>(httpLoanTransactions(loanId));
  }

  generateTransactions(
    loanId: number,
    data: {
      schedType: LoanScheduleType;
      startDate?: string;
    },
  ) {
    const params = removeObjectValues(data, value => value === undefined);
    return this.apiService.get<ApiLoanTransaction[]>(httpLoanTransactionsGenerate(loanId), { params });
  }

  saveTransactions(loanId: number, transactions: LoanTransaction[]) {
    return this.apiService.post(httpLoanTransactions(loanId), transactions.map(this.loanTransactionToApiLoanTransaction));
  }

  getLoanDocList(loanId: number) {
    return this.apiService
      .get<LoanDoc[]>(httpLoanDocs(loanId))
      .pipe(map(docs => docs.map(doc => ({ ...doc, category: doc.categoryid ? +doc.categoryid : 0 }))));
  }

  getLoanDoc(loanId: number, docId: number) {
    return this.apiService.loadFile(httpLoanDocIdDownload(loanId, docId));
  }

  getLoanWithdrawals(params?: { issuer_id: number | null }) {
    return this.apiService.get<LoanWithdrawals[]>(httpLoanWithdrawals(), { params });
  }

  createLoanDoc(loanId: number, data: LoanDoc) {
    this.apiService.post<LoanDoc>(httpLoanDocUpload(loanId), data);
  }

  updateLoanDoc(loanId: number, docId: number, data: Partial<LoanDoc>) {
    return this.apiService.put(httpLoanDocId(loanId, docId), data);
  }

  deleteLoanDoc(loanId: number, docId: number) {
    return this.apiService.delete(httpLoanDocId(loanId, docId));
  }

  getLoanSchedule(loanId: number, type: string, data?: { maturity_date?: string; start_date?: string; principal?: number; rate?: number }) {
    const params = removeObjectValues(data, value => value === undefined);
    return this.apiService.get<ApiLoanTransaction[]>(httpLoanSchedule(loanId, type), { params });
  }

  generateLoanSchedule(
    loanId: number,
    type: string,
    data: { trans_list: LoanTransaction[]; start_date?: string; maturity_date?: string; principal?: number; rate?: number },
  ) {
    const params = removeObjectValues(data, value => value === undefined);
    return this.apiService.post(httpLoanScheduleGenerate(loanId, type), {
      ...params,
      trans_list: params.trans_list.map(this.loanTransactionToApiLoanTransaction),
    });
  }

  activateLoan(loanId: number, transId: number) {
    return this.apiService.post(httpLoanActivate(loanId, transId));
  }

  createLineOfCreditTransaction(
    loanId: number,
    params: {
      amount: number;
    },
  ) {
    return this.apiService.post(httpLoanLineOfCreditTransaction(loanId), params);
  }

  getLineOfCreditVenues() {
    return this.apiService.get(httpLoanLineOfCreditVenues()).pipe(map(res => res as Record<string, number>));
  }

  getLoanIssuerPortfolio(params: { userId: number }) {
    return this.apiService.get<{
      positions: IssuerPosition[];
      summary: IssuerSummary;
    }>(httpLoanIssuerPortfolio(), { params });
  }

  private apiLoanToLoan = (input: ApiLoan): Loan => ({
    ...input,
    contract_date: parseISO(input.contract_date),
    maturity_date: parseISO(input.maturity_date),
  });

  private loanToApiLoan = (input: Loan): ApiLoan => ({
    ...input,
    contract_date: dateAsYYYYMMDD(input.contract_date),
    maturity_date: dateAsYYYYMMDD(input.maturity_date),
  });

  private loanTransactionToApiLoanTransaction = (input: LoanTransaction): any => ({
    amount: input.ammount,
    transtype: input.transtype,
    transsubtype: input.transsubtype,
    effectivedate: dateAsYYYYMMDD(input.effectivedate),
  });
}
