import { TransRec } from '@mca/shared/domain';

export enum LoanStatus {
  New = 1,
  Active,
  Complete,
}

export enum LoanStyle {
  'Interest only' = 1,
  'Line of credit',
}

export enum LoanDayOfWeek {
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
}

interface LoanCommonFields {
  id: number;
  issuer_id: number | null;
  loan_style_id: number;
  recruiter: number | null;
  venue_id: number | null;
  principal: number;
  coupon_rate: number;
  coupon_frequency: number;
  dayofweek: number | null;
  issuer_transit_user: number | null;
  status?: number;
  paid_coupon?: number;
  paid_principal?: number;
  next_coupon_amount?: number;
  balance_principal?: number;
  schedule?: TransRec[];
}

export interface ApiLoan extends LoanCommonFields {
  contract_date: string;
  maturity_date: string;
}

export interface Loan extends LoanCommonFields {
  contract_date: Date;
  maturity_date: Date;
  calc_next_pending_payment_date?: Date;
}
